import { FormWithRedux } from '../../FormWithRedux';
import React from 'react';

export class ACCBISTRecollectionCheck2Form extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('recollectionCheck2Subform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        return true;
    }

    getValueType(identifier) {
        return 'object';
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }

    detailForIdentifier(prefix, value) {
        if (value.identifier === 'recollectionCheck2' && this.getValue(value.identifier) != null) {
            let value = this.getValue('recollectionCheck2');
            if (value.length == 0) {
                return prefix + 'No images recalled';
            }
            else {
                return prefix + 'Recalled ' + value.length + ' image' + (value.length == 1 ? '' : 's');
            }
        }

        return super.detailForIdentifier(prefix, value);
    }

    htmlForIdentifier(prefix, value) {
        if (value.identifier === 'recollectionCheck2' && this.getValue(value.identifier) != null) {
            let checkValues = this.getValue('recollectionCheck2');
            if (checkValues.length == 0) {
                return (<div key={value.identifier} >
                    <label className='list-detail'>{'No images recalled'}</label>
                </div>);
            }
            else {
                return (<div key={value.identifier} >
                    <label className='list-detail'>{'Recalled ' + checkValues.length + ' image' + (checkValues.length == 1 ? '' : 's')}</label>
                </div>);
            }
        }
        else {
            return super.htmlForIdentifier(prefix, value);
        }
    }
}
