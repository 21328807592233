import { redux } from '../../../App';
import { saveWorkflow } from '../../../components/state/actions/WorkflowResponseActions';
import moment from 'moment';
import { TotalEWS, calculateEWS } from '../Custom Types/EWS';
import { removeResultsAlertValue } from '../../../components/state/actions/NotificationsActions';

import { addExpandedCell, removeExpandedCell } from '../../../components/state/actions/SubformStateActions';

export class ACCBISTDependantValuesUpdater {

    constructor(formLoader) {
        this.formLoader = formLoader;
    }

    update(pathwayId, workflow, identifier, value, iterationIndex) {
        updateDependentValues(this.formLoader, pathwayId, workflow, identifier, value, iterationIndex);
    }

    logObservedValues(pathwayId, workflow, form) {
        redux.store.dispatch(saveWorkflow(pathwayId, { 'lastObservedTotalRedFlags': workflow.rawData['totalRedFlags'] }, workflow, false, form, 0));
    }
}

function getLatestAWPTAIndex(workflow) {
    let latestAWPTAIndex = "";
    if(workflow.rawData["awptaCheck5DateTime"]) {
        latestAWPTAIndex = "5";
    } else if (workflow.rawData["awptaCheck4DateTime"]) {
        latestAWPTAIndex = "4";
    } else if (workflow.rawData["awptaCheck3DateTime"]) {
        latestAWPTAIndex = "3";
    } else if (workflow.rawData["awptaCheck2DateTime"]) {
        latestAWPTAIndex = "2";
    } else if (workflow.rawData["awptaCheck1DateTime"]) {
        latestAWPTAIndex = "1";
    }
    return latestAWPTAIndex;
}

function updateDependentValues(formLoader, pathwayId, workflow, identifier, value, iterationIndex) {
    var rawData = workflow.rawData;
    var changedValues = {};

    let subformDependentValues;
    let subformParentValueIdentifier;

    const lastAWPTAIndex = getLatestAWPTAIndex(workflow);

    switch (identifier) {
        case 'patientDOB': {
            if (value != null) {
                let momentDate = moment(value, 'DD/MM/YYYY');
                let years = moment().diff(momentDate, 'years');
                if (!isNaN(+years) && years >= 0) {
                    changedValues['patientAge'] = years;
                } else {
                    changedValues['patientAge'] = null;
                }
            } else {
                changedValues['patientAge'] = null;
            }

            break;
        }
        case 'patientAge': {
            if (rawData['patientDOB'] != null) {
                let momentDate = moment(rawData['patientDOB']);
                let years = moment().diff(momentDate, 'years');
                if (years != null && parseInt(years) != parseInt(value)) {
                    changedValues['patientDOB'] = null;
                }
            }

            break;
        }
        case 'alterationInMentalState': {
            break;
        }
        case 'alteredMentalState':
        case 'retrogradeAmnesia':
        case 'lossOfConsciousness':
        case 'lossOfConsciousnessDuration': {
            let dependentValues = [rawData['lossOfConsciousness'], rawData['retrogradeAmnesia'], rawData['alteredMentalState'], rawData['lossOfConsciousnessDuration']];

            if (rawData['alterationInMentalState'] == null || rawData['alterationInMentalState'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['alterationInMentalState'] = dependentValues.includes(true);
                }

            }

            break;
        }
        case 'intoxicationOrDrugUse':
        case 'psychologicalOrEmotionalTrauma':
        case 'severePain': {
            let dependentValues = [rawData['intoxicationOrDrugUse'], rawData['psychologicalOrEmotionalTrauma'], rawData['severePain']];

            if (rawData['confoundingFactors'] == null || rawData['confoundingFactors'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['confoundingFactors'] = dependentValues.includes(true);
                }

            }

            break;
        }
        case 'depressedSkullFracture':
        case 'openSkullFracture':
        case 'basalSkullFracture': {
            let dependentValues = [rawData['depressedSkullFracture'], rawData['openSkullFracture'], rawData['basalSkullFracture']];

            if (rawData['suspectedSkullFracture'] == null || rawData['suspectedSkullFracture'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['suspectedSkullFracture'] = dependentValues.includes(true);
                }

            }

            break;
        }
        case 'aspirin':
        case 'clopidogrelOrTicagrelor':
        case 'warfarin':
        case 'revaroxabanOrDabigatran':
        case 'otherMedication': {
            let dependentValues = [rawData['aspirin'], rawData['clopidogrelOrTicagrelor'], rawData['warfarin'], rawData['revaroxabanOrDabigatran'], rawData['otherMedication']];

            if (rawData['anticoagulantOrAntiplateletAgent'] == null || rawData['anticoagulantOrAntiplateletAgent'] == undefined) {

                let allCriteriaAnswered = !dependentValues.includes(null) && !dependentValues.includes(undefined);

                if (allCriteriaAnswered) {
                    changedValues['anticoagulantOrAntiplateletAgent'] = dependentValues.includes(true);
                }
            }

            break;
        }
        case 'bistDateTime': {
            let physicalValues = [rawData['headacheBistRating'], rawData['neckHurtsBistRating'], rawData['brightLightsBistRating'], rawData['loudNoisesBistRating']];
            if (physicalValues.includes(null) || physicalValues.includes(undefined)) {
                let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'physicalBistSubform', updatedState.expandedCells));
            }
            break;
        }
        case 'headacheBistRating':
        case 'neckHurtsBistRating':
        case 'brightLightsBistRating':
        case 'loudNoisesBistRating': {
            let physicalValues = [rawData['headacheBistRating'], rawData['neckHurtsBistRating'], rawData['brightLightsBistRating'], rawData['loudNoisesBistRating']];

            if (!physicalValues.includes(null) && !physicalValues.includes(undefined)) {
                let vestibValues = [rawData['dizzySickBistRating'], rawData['closedEyesAtSeaBistRating'], rawData['troubleWithVisionBistRating'], rawData['clumsyBistRating']];

                if (vestibValues.includes(null) || vestibValues.includes(undefined)) {
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'vestibularocularBistSubform', updatedState.expandedCells));
                }
            }

            break;
        }
        case 'dizzySickBistRating':
        case 'closedEyesAtSeaBistRating':
        case 'troubleWithVisionBistRating':
        case 'clumsyBistRating': {

            let vestibValues = [rawData['dizzySickBistRating'], rawData['closedEyesAtSeaBistRating'], rawData['troubleWithVisionBistRating'], rawData['clumsyBistRating']];

            if (!vestibValues.includes(null) && !vestibValues.includes(undefined)) {


                let cognitive = [rawData['longerToThinkBistRating'], rawData['forgetThingsBistRating'], rawData['confusedEasilyBistRating'], rawData['troubleConcentratingBistRating']];

                if (cognitive.includes(null) || cognitive.includes(undefined)) {
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'cognitiveBistSubform', updatedState.expandedCells));
                }
            }
            break;
        }
        case 'longerToThinkBistRating':
        case 'forgetThingsBistRating':
        case 'confusedEasilyBistRating':
        case 'troubleConcentratingBistRating': {
            var shouldShowPost24Hours = false;

            let injuryDateString = rawData['injuryOccuranceDateTime'];
            if (injuryDateString != undefined && injuryDateString != null) {
                let injuryDate = new Date(injuryDateString);
                let now = new Date();
                let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
                let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                shouldShowPost24Hours = hoursBetweenDates > 24;
            }

            let cognitiveValues = [rawData['longerToThinkBistRating'], rawData['forgetThingsBistRating'], rawData['confusedEasilyBistRating'], rawData['troubleConcentratingBistRating']];

            if (shouldShowPost24Hours && !cognitiveValues.includes(null) && !cognitiveValues.includes(undefined)) {
                let postHoursValues = [rawData['angryOrIrritatedBistRating'], rawData['dontFeelRightBistRating'], rawData['tiredDuringDayBistRating'], rawData['sleepMoreOrHardToSleepBistRating']];

                if (postHoursValues.includes(null) || postHoursValues.includes(undefined)) {
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'post24HoursBistSubform', updatedState.expandedCells));
                }
            }
            break;
        }
        case 'angryOrIrritatedBistRating':
        case 'dontFeelRightBistRating':
        case 'tiredDuringDayBistRating':
        case 'sleepMoreOrHardToSleepBistRating': {
            let postHoursValues = [rawData['angryOrIrritatedBistRating'], rawData['dontFeelRightBistRating'], rawData['tiredDuringDayBistRating'], rawData['sleepMoreOrHardToSleepBistRating']];
            if (!postHoursValues.includes(null) && !postHoursValues.includes(undefined) && (rawData['overallImpactScore'] === null || rawData['overallImpactScore'] === undefined)) {
                let updatedState = redux.store.getState().subformStateReducer;
                redux.store.dispatch(addExpandedCell(pathwayId, 'overallImpactScoreSubform', updatedState.expandedCells));
            }
            break;
        }
        //AWPTA Recall Check 1
        case 'recollectionCheck2': {
            let shouldShowFailureCheck = false;

            let recollectionCheckvalue = rawData['recollectionCheck2'];
            shouldShowFailureCheck = recollectionCheckvalue.length < 3;

            if (shouldShowFailureCheck) {

                let failureCheckValue = rawData['recollectionFailureCheck2'];

                if (failureCheckValue == null || failureCheckValue == undefined) {
                    let state = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionCheck2Subform', state.expandedCells));
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'recollectionFailureCheck2Subform', updatedState.expandedCells));
                }
            }
            else {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck2Subform', state.expandedCells));
            }
            break;
        }

        case 'recollectionFailureCheck2': {
            let recollectionFailureValues = [rawData['recollectionFailureCheck2']];

            if (!recollectionFailureValues.includes(null) && !recollectionFailureValues.includes(undefined)) {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck2Subform', state.expandedCells));
            }
            break;
        }

        //AWPTA Recall Check 2
        case 'recollectionCheck3': {
            let shouldShowFailureCheck = false;

            let recollectionCheckvalue = rawData['recollectionCheck3'];
            shouldShowFailureCheck = recollectionCheckvalue.length < 3;

            if (shouldShowFailureCheck) {

                let failureCheckValue = rawData['recollectionFailureCheck3'];

                if (failureCheckValue == null || failureCheckValue == undefined) {
                    let state = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionCheck3Subform', state.expandedCells));
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'recollectionFailureCheck3Subform', updatedState.expandedCells));
                }
            }
            else {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck3Subform', state.expandedCells));
            }
            break;
        }

        case 'recollectionFailureCheck3': {
            let recollectionFailureValues = [rawData['recollectionFailureCheck3']];

            if (!recollectionFailureValues.includes(null) && !recollectionFailureValues.includes(undefined)) {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck3Subform', state.expandedCells));
            }
            break;
        }

        //AWPTA Recall Check 3
        case 'recollectionCheck4': {
            let shouldShowFailureCheck = false;

            let recollectionCheckvalue = rawData['recollectionCheck4'];
            shouldShowFailureCheck = recollectionCheckvalue.length < 3;

            if (shouldShowFailureCheck) {

                let failureCheckValue = rawData['recollectionFailureCheck4'];

                if (failureCheckValue == null || failureCheckValue == undefined) {
                    let state = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionCheck4Subform', state.expandedCells));
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'recollectionFailureCheck4Subform', updatedState.expandedCells));
                }
            }
            else {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck4Subform', state.expandedCells));
            }
            break;
        }

        case 'recollectionFailureCheck4': {
            let recollectionFailureValues = [rawData['recollectionFailureCheck4']];

            if (!recollectionFailureValues.includes(null) && !recollectionFailureValues.includes(undefined)) {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck4Subform', state.expandedCells));
            }
            break;
        }

        //AWPTA Recall Check 4
        case 'recollectionCheck5': {
            let shouldShowFailureCheck = false;

            let recollectionCheckvalue = rawData['recollectionCheck5'];
            shouldShowFailureCheck = recollectionCheckvalue.length < 3;

            if (shouldShowFailureCheck) {

                let failureCheckValue = rawData['recollectionFailureCheck5'];

                if (failureCheckValue == null || failureCheckValue == undefined) {
                    let state = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionCheck5Subform', state.expandedCells));
                    let updatedState = redux.store.getState().subformStateReducer;
                    redux.store.dispatch(addExpandedCell(pathwayId, 'recollectionFailureCheck5Subform', updatedState.expandedCells));
                }
            }
            else {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck5Subform', state.expandedCells));
            }
            break;
        }

        case 'recollectionFailureCheck5': {
            let recollectionFailureValues = [rawData['recollectionFailureCheck5']];

            if (!recollectionFailureValues.includes(null) && !recollectionFailureValues.includes(undefined)) {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'recollectionFailureCheck5Subform', state.expandedCells));
            }
            break;
        }
        case 'heartRate':
        case 'bloodPressure':
        case 'bodyTemperature':
        case 'oxygenSaturation':
        case 'respiratoryRate':
        case 'supplementalOxygen':
        case 'normalVitalSignsCheck':
        case 'levelOfConsciousness': {
            let totalEWS = getTotalEWS(rawData);

            if (totalEWS != null && totalEWS != undefined) {
                changedValues['totalEWS'] = totalEWS.sum;
                changedValues['ewsCriteriaRedflag'] = totalEWS.sum >= 2 && rawData['normalVitalSignsCheck'] != true;
            } else {
                changedValues['totalEWS'] = null;
                changedValues['ewsCriteriaRedflag'] = rawData['normalVitalSignsCheck'] == true ? false : null;
            }

            break;
        }
        case 'arrivalGCS':
        case 'awptaCheck5DateTime':
        case 'awptaCheck4DateTime':
        case 'awptaCheck3DateTime':
        case 'awptaCheck2DateTime': {

            var checks = [
                'awptaCheck5DateTime',
                'awptaCheck4DateTime',
                'awptaCheck3DateTime',
                'awptaCheck2DateTime'];

            var lastCheckDate;

            for (let check of checks) {
                let date = rawData[check];
                if (date != null && date != undefined) {
                    lastCheckDate = date;
                    break;
                }
            }

            let injuryDateString = rawData['injuryOccuranceDateTime'];

            let timeInPTA;

            if (injuryDateString != undefined && injuryDateString != null && lastCheckDate != undefined && lastCheckDate != null, rawData['totalAWPTA'] != null && rawData['totalAWPTA'] != undefined && rawData['totalAWPTA'] < 18) {
                let injuryDate = new Date(injuryDateString);
                let lastAWPTADate = new Date(lastCheckDate);

                let msBetweenDates = Math.abs(injuryDate.getTime() - lastAWPTADate.getTime());
                timeInPTA = msBetweenDates / (60 * 60 * 1000);
            }

            rawData['timeInPTA'] = timeInPTA;

            if (identifier === `awptaCheck${lastAWPTAIndex}DateTime`) {
                // Copy all values in the form when the new A-WPTA form appears
                [   'neuroObsDateTime',
                    'neuroObsOkayCheck',
                    'eyeOpeningScore',
                    'verbalScore',
                    'motorScore',
                    'leftPupilSize',
                    'leftPupilReaction',
                    'rightPupilSize',
                    'rightPupilReaction'
                ].forEach(copyIdentifier => {
                    if(rawData[copyIdentifier + lastAWPTAIndex] !== rawData[copyIdentifier]) {
                        changedValues[copyIdentifier] = rawData[copyIdentifier + lastAWPTAIndex];
                    }
                });
                break;
            }
            break;
        }
        case 'neuroObsOkayCheck': {
            let dependentValues = [rawData['eyeOpeningScore'], rawData['verbalScore'], rawData['motorScore']].filter(n => n);
            
            if (dependentValues.length == 0 && rawData['neuroObsOkayCheck'] == true) {
                let state = redux.store.getState().subformStateReducer;
                redux.store.dispatch(removeExpandedCell(pathwayId, 'gcsSectionSubform', state.expandedCells));
            }

            // Copy value to the latest A-WPTA
            if(lastAWPTAIndex !== "" && rawData[identifier] !== rawData[identifier + lastAWPTAIndex]) {
                changedValues[identifier + lastAWPTAIndex] = rawData[identifier];
            }
            break;
        }
        case 'neuroObsOkayCheck' + lastAWPTAIndex: {
            // Copy value to the Neuro Obs form
            if(rawData[identifier] !== rawData['neuroObsOkayCheck']) {
                changedValues['neuroObsOkayCheck'] = rawData[identifier];
            }
            break;
        }
        case 'neuroObsDateTime':
        case 'eyeOpeningScore':
        case 'verbalScore':
        case 'motorScore':
        case 'totalGCSScore':
        case 'leftPupilSize':
        case 'leftPupilReaction':
        case 'rightPupilSize':
        case 'rightPupilReaction': {
            // Copy value to the latest A-WPTA
            if(lastAWPTAIndex !== "" && rawData[identifier] !== rawData[identifier + lastAWPTAIndex]) {
                changedValues[identifier + lastAWPTAIndex] = rawData[identifier];
            }
            break;
        }
        case 'neuroObsDateTime' + lastAWPTAIndex: 
        case 'eyeOpeningScore' + lastAWPTAIndex: 
        case 'verbalScore' + lastAWPTAIndex:
        case 'motorScore' + lastAWPTAIndex:
        case 'leftPupilSize' + lastAWPTAIndex:
        case 'leftPupilReaction' + lastAWPTAIndex:
        case 'rightPupilSize' + lastAWPTAIndex:
        case 'rightPupilReaction' + lastAWPTAIndex: {
            const obsFormIdentifier = identifier.slice(0, -1);
            // Copy value to the Neuro Obs form
            if(rawData[identifier] !== rawData[obsFormIdentifier]) {
                changedValues[obsFormIdentifier] = rawData[identifier];
            }
            break;
        }
        case 'whatIsYourNameCheck':
        case 'nameOfThisPlaceCheck':
        case 'whyAreYouHereCheck':
        case 'whatMonthAreWeInCheck':
        case 'whatYearAreWeInCheck': {
            if (!isOrientationsNormal(workflow, "")) {
                changedValues['verbalScore'] = "Confused";
            }
            break;
        }
        case 'whatIsYourNameCheck1':
        case 'nameOfThisPlaceCheck1':
        case 'whyAreYouHereCheck1':
        case 'whatMonthAreWeInCheck1':
        case 'whatYearAreWeInCheck1': {
            if (!isOrientationsNormal(workflow, "1")) {
                changedValues['verbalScore1'] = "Confused";
            }
            break;
        }
        case 'whatIsYourNameCheck2':
        case 'nameOfThisPlaceCheck2':
        case 'whyAreYouHereCheck2':
        case 'whatMonthAreWeInCheck2':
        case 'whatYearAreWeInCheck2': {
            if (!isOrientationsNormal(workflow, "2")) {
                changedValues['verbalScore2'] = "Confused";
            }
            break;
        }
        case 'whatIsYourNameCheck3':
        case 'nameOfThisPlaceCheck3':
        case 'whyAreYouHereCheck3':
        case 'whatMonthAreWeInCheck3':
        case 'whatYearAreWeInCheck3': {
            if (!isOrientationsNormal(workflow, "3")) {
                changedValues['verbalScore3'] = "Confused";
            }
            break;
        }
        case 'whatIsYourNameCheck4':
        case 'nameOfThisPlaceCheck4':
        case 'whyAreYouHereCheck4':
        case 'whatMonthAreWeInCheck4':
        case 'whatYearAreWeInCheck4': {
            if (!isOrientationsNormal(workflow, "4")) {
                changedValues['verbalScore4'] = "Confused";
            }
            break;
        }
        case 'whatIsYourNameCheck5':
        case 'nameOfThisPlaceCheck5':
        case 'whyAreYouHereCheck5':
        case 'whatMonthAreWeInCheck5':
        case 'whatYearAreWeInCheck5': {
            if (!isOrientationsNormal(workflow, "5")) {
                changedValues['verbalScore5'] = "Confused";
            }
            break;
        }
    }

    let totalRedFlags = getActiveRedFlagCount(rawData);

    let stringChanges = [];

    let lastObservedValue = rawData['lastObservedTotalRedFlags'] == null ? 0 : rawData['lastObservedTotalRedFlags'];
    let lastValue = rawData['totalRedFlags'] == null ? 0 : rawData['totalRedFlags'];

    if (lastValue != totalRedFlags) {
        if ((lastObservedValue == 0 && totalRedFlags != 0) || (lastObservedValue > 0 && totalRedFlags == 0)) {
            if ((lastValue == 1 && totalRedFlags == 0) || (totalRedFlags == 1 && lastValue == 0)) {
                stringChanges.push('Red Flags');
            }
        } else {
            redux.store.dispatch(removeResultsAlertValue(pathwayId, 'Red Flags'));
        }
        changedValues['totalRedFlags'] = totalRedFlags;
    }


    if (Object.keys(changedValues).length > 0) {
        let mainForm = formLoader.mainForm(pathwayId);
        mainForm.save(changedValues, workflow, true, iterationIndex + 1);
    }
}

function isOrientationsNormal(workflow, orientationIndex) {
    let orientationChecks = [
        workflow.rawData['whatIsYourNameCheck' + orientationIndex],
        workflow.rawData['nameOfThisPlaceCheck' + orientationIndex],
        workflow.rawData['whyAreYouHereCheck' + orientationIndex],
        workflow.rawData['whatMonthAreWeInCheck' + orientationIndex],
        workflow.rawData['whatYearAreWeInCheck' + orientationIndex]];

    if(!orientationChecks.includes(null) && !orientationChecks.includes(undefined) && orientationChecks.includes(false)) {
        return false;
    } else {
        return true;
    }
};

function getActiveRedFlagCount(rawData) {
    let redFlagIdentifiers = [
        'ewsCriteriaRedflag',
        'ctCriteraRedflag',
        'reducedGCSRedFlag',
        'highRiskSymptomsOrSignsRedFlag'
    ];

    let values = redFlagIdentifiers.map((identifier) => {
        return rawData[identifier] == true ? true : null;
    });

    return values.filter(n => n).length;
}

function getTotalEWS(data) {
    let ewsIdentifiers = [
        'heartRateEWS',
        'bloodPressureEWS',
        'bodyTemperatureEWS',
        'oxygenSaturationEWS',
        'respiratoryRateEWS',
        'supplementalOxygenEWS',
        'levelOfConsciousnessEWS'
    ];

    let categories = ewsIdentifiers.map((identifier) => {
        let value = data[identifier.replace('EWS', '')];
        let ews = calculateEWS(identifier, value);
        return ews;
    }).filter(n => n);

    // Only display an EWS if all categories are represented.
    if (categories.length < ewsIdentifiers.length) {
        return null;
    }

    let declaredNormal = data['areSignsNormal'];
    return new TotalEWS(categories, declaredNormal);
}