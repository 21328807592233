import { FormWithRedux } from '../../FormWithRedux';
import { ACCBISTInformationForm } from './ACCBIST-IntroForm';
import { ACCBISTPatientDetailsForm } from './ACCBIST-PatientDetails';
import { ACCBISTInjuryDetailsForm } from './ACCBIST-InjuryDetailsForm';
import { ACCBISTClinicalAssessmentForm } from './ACCBIST-ClinicalAssessmentForm';
import { ACCBISTSymptonScaleForm } from './ACCBIST-SymptomScaleForm';

import { ACCBISTAWPTAForm } from './ACCBIST-AWPTAForm'; 
import { ACCBISTAWPTA2Form } from './ACCBIST-AWPTA2Form';
import { ACCBISTAWPTA3Form } from './ACCBIST-AWPTA3Form';
import { ACCBISTAWPTA4Form } from './ACCBIST-AWPTA4Form';
import { ACCBISTAWPTA5Form } from './ACCBIST-AWPTA5Form';
import { ACCBISTResultsForm } from './ACCBIST-ResultsForm'; 
import { ACCBISTNeuroObsForm } from './ACCBIST-NeuroObsForm';
import { ACCBISTVitalSignsForm } from './ACCBIST-VitalSignsForm';
import { ACCBISTCTBrainForm } from './ACCBIST-CTBrainForm';

import { ACCBISTNotesForm } from './ACCBIST-NotesForm';

import { ACCBISTActions } from '../Actions/ACCBIST-Actions';
import { ACCBISTDependantValuesUpdater } from '../Calculations/ACCBIST-DependantValuesUpdater';
import { ACCBISTCalculations } from '../Calculations/ACCBIST-Calculations';

import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { ACCBISTResearchForm } from './ACCBIST-ResearchForm';

export class ACCBISTMainForm extends FormWithRedux {

    constructor(formLoader) {
        super('mainForm', formLoader, null, new ACCBISTActions(), new ACCBISTDependantValuesUpdater(formLoader), new ACCBISTCalculations());
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'introForm':
                return new ACCBISTInformationForm(this.formLoader);
            case 'notesForm':
                return new ACCBISTNotesForm(this.formLoader, this, this.dependentValuesUpdater);
            case 'researchForm':
                return new ACCBISTResearchForm(this.formLoader, this, this.dependentValuesUpdater);
            case 'resultsForm':
                return new ACCBISTResultsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'patientDetailsSubform':
                return new ACCBISTPatientDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'injuryDetailsSubform':
                return new ACCBISTInjuryDetailsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'clinicalAssessmentSubform':
                return new ACCBISTClinicalAssessmentForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck1Subform':
                return new ACCBISTAWPTAForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck2Subform':
                return new ACCBISTAWPTA2Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck3Subform':
                return new ACCBISTAWPTA3Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck4Subform':
                return new ACCBISTAWPTA4Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'awptaCheck5Subform':
                return new ACCBISTAWPTA5Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'bistSymptomScale':
                return new ACCBISTSymptonScaleForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'gcsSubform':
                return new ACCBISTNeuroObsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, "");
            case 'vitalSignsSubform':
                return new ACCBISTVitalSignsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'ctBrainSubform':
                return new ACCBISTCTBrainForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        let subform = this.subform(identifier);
        if (subform != null && subform != undefined && typeof subform.shouldShowInSidebar === 'function') {
            return subform.shouldShowInSidebar();
        }

        return true;
    }

    isEnabled(identifier) {
        if (identifier != null) {
            return true;
        }
        return false;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return null;
    }

    submissionValidation(identifier) {
        return [];
    }

    detailString() {
        return this.detail;
    }
}