// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ExportToHCSOverlay } from "../pages/Overlays/ExportToHCSOverlay";
import SubmitToFHIROverlay from "./Overlays/SubmitToFHIROverlay";
import { EmailOverlay } from "../pages/Overlays/EmailOverlay";
import ReactDOMServer from "react-dom/server";
import { ENABLED_EXPORTS } from "../../config/config";
import Logo from "../../images/logo-white.png";

import {
  createNewResponse,
  clearWorkflow,
  markWorkFlowAsExported,
} from "../state/actions/WorkflowResponseActions";

import "../../styles/components/formSummary.scss";
import { FormLabel } from "../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel";
import { FormButton } from "../../waferJS/FormsUI/FormsUI Components/Controls/FormButton";

import { Base64 } from "../../helpers/Base64Encoder";
import { PathwayFormatter } from "../../forms/PathwayFormatter";
import { FormsStyles } from "../../waferJS/FormsUI/FormsUI";
import { withRouter } from "../routers/withRouter";

// COMPONENT
class FormSummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exportHCSVisible: false,
      exportEmailVisible: false,
      submitPathwayVisible: false,
      plainTextPathway: PathwayFormatter.formatAsPlainText(
        this.props.formLoader,
        this.props.match.params.pathwayId,
        this.props.workflows[this.props.match.params.pathwayId]
      ),
      showCopySuccess: false,
    };

    this.showPrintPrompt = this.showPrintPrompt.bind(this);
    this.copyText = this.copyText.bind(this);
    this.toggleExportOverlay = this.toggleExportOverlay.bind(this);
    this.removeSuccessLabel = this.removeSuccessLabel.bind(this);
    this.toggleEmailOverlay = this.toggleEmailOverlay.bind(this);
    this.toggleFHIROverlay = this.toggleFHIROverlay.bind(this);
  }

  componentDidMount() {}

  showPrintPrompt() {
    this.props.markWorkFlowAsExported(
      this.props.match.params.pathwayId,
      this.props.workflows[this.props.match.params.pathwayId]
    );
    window.print();
  }

  copyText() {
    this.node.select();
    document.execCommand("copy");
    this.setState({ showCopySuccess: true });
    this.resetTimeout = setTimeout(this.removeSuccessLabel, 1900);
    this.props.markWorkFlowAsExported(
      this.props.match.params.pathwayId,
      this.props.workflows[this.props.match.params.pathwayId]
    );
  }

  removeSuccessLabel() {
    this.setState({ showCopySuccess: false });
  }

  toggleExportOverlay() {
    if (this.state.exportHCSVisible) {
      this.props.clearWorkflow("emailExport");
      document.body.classList.remove("disable-scroll");
    } else {
      if (this.props.workflows["hcsExport"] == null) {
        this.props.createNewResponse("hcsExport");
      }
      document.body.classList.add("disable-scroll");
    }
    this.setState({ exportHCSVisible: !this.state.exportHCSVisible });
  }

  toggleEmailOverlay() {
    if (this.state.exportEmailVisible) {
      document.body.classList.remove("disable-scroll");
    } else {
      document.body.classList.add("disable-scroll");
    }
    this.setState({ exportEmailVisible: !this.state.exportEmailVisible });
  }

  toggleFHIROverlay() {
    if (this.state.submitPathwayVisible) {
      document.body.classList.remove("disable-scroll");
    } else {
      document.body.classList.add("disable-scroll");
    }
    this.setState({ submitPathwayVisible: !this.state.submitPathwayVisible });
  }

  constructLaunchInWayfindLink() {
    let dataString = JSON.stringify(this.props.workflows[this.props.match.params.pathwayId]);
    let dataObjectBase64 = Base64.encode(dataString);
    return ReactDOMServer.renderToStaticMarkup(
      <a
        href={
          window.location.origin +
          "/" +
          this.props.match.params.pathwayId +
          "/handover?data=" +
          dataObjectBase64
        }
        className="open-in-wayfind-button"
      >
        Open in Wayfind
      </a>
    );
  }

  render() {
    let params = new URLSearchParams(window.location.search);
    let exportType = params.get("type");
    let workflow = this.props.workflows[this.props.match.params.pathwayId];
    let formSummaryFormat;
    if (workflow != null) {
      if (exportType == "JSON" || exportType == "json") {
        formSummaryFormat = <div>{JSON.stringify(this.state.workflow.rawData)}</div>;
      } else {
        formSummaryFormat = this.props.formLoader.summaryComponent(
          this.props.match.params.pathwayId
        );
      }
    }

    let htmlBody;
    if (document.getElementsByClassName("form-summary-container")[0] != null) {
      htmlBody =
        document.getElementsByClassName("form-summary-container")[0].outerHTML +
        this.constructLaunchInWayfindLink();
    }

    return (
      <div>
        {this.state.exportHCSVisible && (
          <ExportToHCSOverlay
            formLoader={this.props.formLoader}
            toggleOverlay={this.toggleExportOverlay}
            hcsWorkflow={this.props.workflows["hcsExport"]}
            htmlBody={htmlBody}
          />
        )}
        {this.state.exportEmailVisible && (
          <EmailOverlay
            toggleOverlay={this.toggleEmailOverlay}
            pathwayWorkflow={this.props.workflows[this.props.match.params.pathwayId]}
            htmlBody={htmlBody}
            pathwayId={this.props.match.params.pathwayId}
            data={this.props.workflows[this.props.match.params.pathwayId]}
            plainTextExport={this.state.plainTextPathway}
            initialUserName={this.props.userName}
            initialUserEmail={this.props.userEmail}
          />
        )}
        {this.state.submitPathwayVisible && (
          <SubmitToFHIROverlay
            open={this.state.submitPathwayVisible}
            handleClose={() => this.toggleFHIROverlay()}
          />
        )}

        <div className="print-head">
          <img src={Logo} className="print-image" />
          <div className="print-head-text">A NZ TBI Assessment Generated by Wayfind</div>
        </div>
        <div className="print-header">
          <label className="list-title">Export Options</label>
          <FormLabel
            value={
              'The data captured in this form is only stored locally on your browser. Use the following export options to save the data in other formats for exporting to other services. To export as PDF, use your browser\'s Save or Print features. Use "Email" to email a plain text summary to your inbox. Use "Finish Pathway" to complete the pathway and save data to FHIR Server.'
            }
            fontSize={0.9}
            textColor={"#B0B0B0"}
          />
          <div className={"print-button-container"}>
            {ENABLED_EXPORTS.includes("hcs") && (
              <FormButton
                title={"Upload to HCS"}
                enabled={true}
                isSelected={false}
                onPress={this.toggleExportOverlay}
              />
            )}
            <div className="plain-text-container">
              <FormButton
                title={"Copy as Plain Text"}
                color={
                  this.state.showCopySuccess
                    ? FormsStyles.solidBackgroundColor
                    : FormsStyles.primaryTint
                }
                enabled={true}
                isSelected={false}
                onPress={this.copyText}
              />
              <label
                className={"copy-success " + (this.state.showCopySuccess ? "show-success" : "")}
              >
                Copied to Clipboard
              </label>
            </div>

            <FormButton
              title={"Print / PDF"}
              enabled={true}
              isSelected={false}
              onPress={this.showPrintPrompt}
            />
            <FormButton
              title={"Email"}
              enabled={true}
              isSelected={false}
              onPress={this.toggleEmailOverlay}
            />
            {this.props.match.params.pathwayId === "accbist01" && (
              <FormButton
                title={"Finish Pathway"}
                noImagePB={true}
                enabled={true}
                isSelected={false}
                onPress={this.toggleFHIROverlay}
              />
            )}

            <textarea
              className={"summary-plaintext-input"}
              rows={1}
              value={this.state.plainTextPathway}
              readOnly
              ref={(node) => (this.node = node)}
            />
          </div>
        </div>
        {formSummaryFormat}
      </div>
    );
  }
}

FormSummaryPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  workflows: PropTypes.object.isRequired,
  calculations: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  createNewResponse: PropTypes.func.isRequired,
  clearWorkflow: PropTypes.func.isRequired,
  markWorkFlowAsExported: PropTypes.func.isRequired,
  formLoader: PropTypes.object.isRequired,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
  const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } =
    state.workflowReducer;
  const { calculations } = state.calculationReducer;
  const { actions } = state.actionsReducer;
  return {
    workflows,
    updatingWorkflowPending,
    updatingWorkflowFulfilled,
    updatingWorkflowFailed,
    calculations,
    actions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createNewResponse, clearWorkflow, markWorkFlowAsExported }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSummaryPage));

// EXPORT COMPONENT

export { hoc as FormSummaryPage };
