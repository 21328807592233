import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTResearchForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('researchForm', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);
        return true;
    }

    isEnabled() {
      return true;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        return 'string';
    }

    submissionValidation() {
        return [];
    }
}